import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper'
import ManuverCard from './manuverCard'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
class ManuverBar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selectedRadio : [151,578]
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event){
    console.log(event.target.value)
    this.setState({selectedRadio : event.target.value})
  }
  render(){
    return(
      <Paper elevation={4}>
        <h3>Identified Manuvers</h3>
        <FormControl component="fieldset">
          <FormLabel component="legend">Manuvers</FormLabel>
          <RadioGroup aria-label="Manuvers" name="manuvers1" value={this.state.selectedRadio} onChange={this.handleChange}>
          <FormControlLabel value={[151,578]} control={<Radio />} label="Pattern 1" />
          <FormControlLabel value={[578,894]} control={<Radio />} label="Pattern 2" />
          <FormControlLabel value={[894,1214]} control={<Radio />} label="Pattern 3" />
          </RadioGroup>
        </FormControl>
      </Paper>
    )
  }
}

export default ManuverBar
