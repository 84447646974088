import * as React from 'react';
import './App.css';
import MyResponsiveGrid from './mainGridLayout'

function App() {

  
  return(
    <div>
      <MyResponsiveGrid></MyResponsiveGrid>
    </div>
  )
}

export default App;
