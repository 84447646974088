import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import CesiumMap from './cesiumMap'
import ManuverBar from './manuverBar'
import TestGraph from './testgraph'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider';
import Drawer from '@material-ui/core/Drawer';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import CodeIcon from '@material-ui/icons/Code';
import TimelineIcon from '@material-ui/icons/Timeline';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
class CloudDebreif extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      manuversHidden : true,
      graphHidden : true,
      timelineHidden : true,
      lats : [],
      longs :[],
      alts : [],
      gs : [],
      as : [],
      headings : [],
      startTime : 1,
      endTime : props.longs.length,
      testStartTime : 0,
      manuverSplits : [
        [ 1, props.longs.length, 'Full Flight' ],
        [ 1, 112, 'Departure' ],
        [ 151, 578, 'Go Around' ],
        [ 578, 894, 'Touch and Go 1' ],
        [ 894, 1214, 'Touch and Go 2' ],
        [ 1214, 1536, 'Touch and Go 3' ],
        [ 1536, 1859, 'Touch and Go 4' ],
        [ 1859, 2189, 'Touch and Go 5' ],
        [ 2189, 2528, 'Touch and Go 6' ],
        [ 2528, 2859, 'Touch and Go 7' ],
        [ 2859, 3195, 'Go Around' ],
        [ 3195, 3477, 'Touch and Go 8' ],
        [ 3504, 3997, 'Full Stop' ]
      ],
      manuverElements : [],
      selectedRadio : 0,
      docID : "",
      correctedAlts : [],
      map : [],
      landings : [],
      loaded : false
    }
    this.toggleElements = this.toggleElements.bind(this)
    this.changeTime = this.changeTime.bind(this)
    this.calculateAltitudes = this.calculateAltitudes.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.generateGraphAlts = this.generateGraphAlts.bind(this)
    this.setup = this.setup.bind(this)
  }
  componentDidMount(){
    const db = firebase.firestore();

    const docRef = db.collection('reesj4').doc('1622825894')
    let a = this
    docRef.get().then(function(doc){
        let lats = doc.data().lats
        let longs = doc.data().longs
        let alts = doc.data().alts
        var gs = doc.data().gs
        var as = doc.data().as
        var headings = doc.data().headings
        var correctedAlts = doc.data().correctedAlts
        var landings = doc.data().tdPoints
        a.setState({lats:lats,longs:longs,alts:alts,gs:gs,as:as,headings:headings,correctedAlts:correctedAlts,landings:landings,loaded:true},()=>a.setup())
    })
  }
  setup(){
    this.setState({map:<CesiumMap lats = {this.state.lats} longs={this.state.longs} alts={this.state.correctedAlts} startTime = {this.state.startTime} endTime = {this.state.endTime} as = {this.state.as} gs = {this.state.gs} headings = {this.state.headings}/>})
    this.generateGraphAlts()
    let a = this
    var manuvers = []
    for(var i = 0; i < this.state.manuverSplits.length; i ++){
      manuvers.push(<FormControlLabel value={i} control={<Radio />} label={this.state.manuverSplits[i][2]} />)
      if(i == this.state.manuverSplits.length - 1){
        a.setState({manuverElements:manuvers})
      }
    }
  }
  toggleElements(value){
    let a = this
    if(value == 0){
      let mH = !a.state.manuversHidden
      a.setState({manuversHidden:mH})
    }
    if(value == 1){
      let gH = !a.state.timelineHidden
      a.setState({timelineHidden:gH})
    }
    if(value == 2){
      let gH = !a.state.graphHidden
      a.setState({graphHidden:gH})
    }
  }
  changeTime(event, newValue){
    this.setState({startTime : newValue[0]})
    this.setState({endTime : newValue[1]})
    this.calculateAltitudes(newValue[0],newValue[1])
    this.generateGraphAlts()
  }
  calculateAltitudes(start, end){
    var range = []
    for(var i = start; i < end; i++){
      range.push(this.state.alts[i])
      if(i == end-1){
        console.log(Math.max(...range))
        console.log(Math.min(...range))
      }
    }
  }

  generateGraphAlts(){
    console.log(this.state.as)
    console.log("GENERATING GRAPH ALTSS")
    let a = this
    let workingAlts = this.state.alts
    let workingAS = this.state.as
    console.log(workingAS)
    let altData = []
    let carrySpeed = workingAS[this.state.startTime]
    for(var i = this.props.startTime; i < this.props.endTime; i ++){
    
     console.log(workingAlts[i])
     console.log(workingAS[i])
     altData.push({
      alts: parseInt(workingAlts[i]),
      as: parseInt(workingAS[i])
    })
     /*
     if(workingAS[i]<carrySpeed*1.5){
      altData.push({
        alts: parseInt(workingAlts[i]),
        as: parseInt(workingAS[i])
      })
      carrySpeed = workingAS[i]
    }else{
      altData.push({
        alts: parseInt(workingAlts[i]),
        as: carrySpeed
      })
    }
      if(i == a.state.endTime - 1){
        a.setState({altData:altData})
        //console.log(workingAlts)
      }
      */
    }
  }

  handleChange(event){
    console.log(event.target.value)
    let times = this.state.manuverSplits[event.target.value]
    console.log(times)
    //this.setState({startTime : times[0]}, this.setState({endTime : times[1]}))
    this.setState({startTime : times[0],endTime : times[1], selectedRadio : event.target.value})
    this.generateGraphAlts()
    //this.setState({selectedRadio : event.target.value})
  }
  render(){
    return(
      <div>

        <Grid container>
          <Grid item xs = {2}>
            <Paper style = {{zIndex : 2, position : 'absolute', marginTop : '325px'}} hidden={this.state.manuversHidden}>
            <Paper elevation={4}>
             <h3>Identified Manuvers</h3>
              <RadioGroup aria-label="Manuvers" name="manuvers1" value={this.state.selectedRadio} onChange={this.handleChange}>
              {this.state.manuverElements}
              </RadioGroup>
            
          </Paper>
            </Paper>
          </Grid>
          <Grid item xs = {12}>
          <Paper style = {{zIndex : 1, position : 'absolute',marginTop : '85vh', width : '100vw', textAlign:'center'}} hidden={this.state.timelineHidden}>
            <h3>Time Line</h3>
            <Slider style={{marginLeft:'2.5vw',marginRight:'2.5vw', width:'95vw'}} min = {1} max = {this.state.longs.length} onChange={(event, newValue) => {this.changeTime(event,newValue)}} value={[this.state.startTime,this.state.endTime]} valueLabelDisplay="auto" aria-labelledby="range-slider"/>
          </Paper>
          </Grid>
          <Grid item xs = {12}>
          <Paper style = {{zIndex : 1, position : 'absolute', marginTop : '5vh', }} hidden={this.state.graphHidden}>
          <LineChart width={window.innerWidth} height={250} data={this.state.altData} margin={{top: 5,right: 30,left: 20,bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis yAxisId = "left"  type="number" domain={['auto', 'auto']}/>
      <YAxis yAxisId="right" orientation="right" />
      <Tooltip />
      <Legend />
      <Line
        type="linear"
        dataKey="as"
        stroke="#8884d8"
        yAxisId="right"
        dot = {false}
      />
      <Line type="monotone" dataKey="alts" stroke="#82ca9d" yAxisId="left" dot = {false}/>
    </LineChart>
          </Paper>
          </Grid>
          <CesiumMap lats = {this.state.lats} longs={this.state.longs} alts={this.state.correctedAlts} startTime = {this.state.startTime} endTime = {this.state.endTime} as = {this.state.as} gs = {this.state.gs} headings = {this.state.headings} loaded = {this.state.loaded}/>

        </Grid>
        <BottomNavigation onChange={(event, newValue) => {this.toggleElements(newValue)}} showLabels style = {{zIndex : 4, position: 'fixed', bottom: '0', width : '100vw', backgroundColor : 'grey'}}>
         
          <BottomNavigationAction label="Manuvers" icon={<CodeIcon />} />
          <BottomNavigationAction label="Timeline" icon={<TimelineIcon />} />
          <BottomNavigationAction label="Charts" icon={<MultilineChartIcon />} />
        </BottomNavigation>
      </div>
    )
  }
}
export default CloudDebreif
