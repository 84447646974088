import React, { Component } from 'react';
import seerLogo from "./Seer_logo.png";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { thisBooleanValue } from 'es-abstract';
class Login extends Component{
    constructor(props){
        super(props)
        this.state = {
            user : '',
            pass : '',
            code : '',
            secret : 'KMMX',
            errorCode : ''
        }
        this.onTextChange=this.onTextChange.bind(this)
        this.loginButtonPressed = this.loginButtonPressed.bind(this)
    }
    onTextChange(event){
        this.setState({[event.target.id]:event.target.value})
    }
    loginButtonPressed(){
        let a = this
        firebase.auth().signInWithEmailAndPassword(this.state.user,this.state.pass).then((userCredential)=>{
            var user = userCredential.user
            console.log(user)
            console.log(firebase.auth().currentUser.email)
        }).catch((error) => {
            var errorCode = error.code 
            var errorMessage = error.errorMessage
            console.log('Error')
            console.log(errorCode)
            a.setState({errorCode : 'Failed to Login ' + errorCode})
        })
    }
    render(){
        return(
            <div style={{alignItems:'center', marginTop:'30vh', marginLeft:'40vw'}}>
                <img src={seerLogo}  width="512"></img>
                <h3></h3>
                <TextField id="user" label="Email" style = {{width:512}} onChange={this.onTextChange}/>
                <h3></h3>
                <TextField id="pass" label="Password" type="password" style = {{width:512}} onChange={this.onTextChange}/>
                <h3></h3>
                <TextField id="code" label="Code" type="password" style = {{width:512}} onChange={this.onTextChange}/>
                <h3></h3>
                <Button style={{width:512}} onClick={this.loginButtonPressed}>Login</Button>
                <h4 style = {{color:'red', align : 'center'}}>{this.state.errorCode}</h4>
            </div>
        )
    }
}

export default Login