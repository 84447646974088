import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import LoadFromURL from './loadFromURL'
import App from './App';
import Login from './login'
import FlightSelection from './cloudFlightSelection'
export default function Director(){
    return(
        <Router>
            <Switch>
                <Route exact path="/" children={<Child2 />} />
                <Route path="/flight/:user/:id" children={<Child />} />
                <Route path="/flights/:user/" children={<Child3/>}/>
            </Switch>
        </Router>
    );

}

function Child3(){
    let {user} = useParams();
    return(<FlightSelection user ={user}></FlightSelection>)
}


function Child2(){
    console.log("Checking Login")
    firebase.auth().onAuthStateChanged(function(user){
        if(user){
            return(<App></App>)
        }else{
            return(<Login></Login>)
        }
    })
    if(firebase.auth().currentUser!=null){
        return(<App></App>)
    }else{
        return(<Login></Login>)
    }
    
}

function Child() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();
    let {user} = useParams();
    return(<LoadFromURL id={id} user ={user}></LoadFromURL>)
    /*
    var db = firebase.firestore()
    const docRef = db.collection(user).doc('flights').collection('flights').doc(id)
    docRef.get().then(function(doc){
        if(doc.exists){
              
    return (
        <div>
          <h3>Flight ID: {id}</h3>
          <h3>User: {user}</h3>
        </div>
      );
        }else{
  
            return (
                <div>
                  <h3>Doc Not Found</h3>
                </div>
              );
        }
    })
    */
}

