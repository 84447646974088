import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import CesiumMap from './cesiumMap'
import ManuverBar from './manuverBar'
import TestGraph from './testgraph'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import CodeIcon from '@material-ui/icons/Code';
import TimelineIcon from '@material-ui/icons/Timeline';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CreateIcon from '@material-ui/icons/Create';
import NotesIcon from '@material-ui/icons/Notes';
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import {SketchField, Tools} from 'react-sketch-whiteboard';
import {isMobile} from 'react-device-detect';
import LandingDisplay from './landingDisplay';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import Draggable from 'react-draggable';
import { ContactSupportOutlined } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FlightLandIcon from '@material-ui/icons/FlightLand';
class DebreifPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      manuversHidden : true,
      graphHidden : true,
      timelineHidden : true,
      lats : props.lats,
      longs : props.longs,
      alts : props.alts,
      gs : props.gs,
      as : props.as,
      headings : props.headings,
      startTime : 1,
      endTime : props.longs.length,
      testStartTime : 0,
      manuverSplits : [
        [ 1, props.longs.length, 'Full Flight' ],
        [ 1, 112, 'Departure' ],
        [ 151, 578, 'Go Around' ],
        [ 578, 894, 'Touch and Go 1' ],
        [ 894, 1214, 'Touch and Go 2' ],
        [ 1214, 1536, 'Touch and Go 3' ],
        [ 1536, 1859, 'Touch and Go 4' ],
        [ 1859, 2189, 'Touch and Go 5' ],
        [ 2189, 2528, 'Touch and Go 6' ],
        [ 2528, 2859, 'Touch and Go 7' ],
        [ 2859, 3195, 'Go Around' ],
        [ 3195, 3477, 'Touch and Go 8' ],
        [ 3504, 3997, 'Full Stop' ]
      ],
      manuvers : props.manuvers,
      manuverElements : [],
      selectedRadio : 0,
      drawHidden : false,
      lineColor : 'black',
      speedDots : false,
      touchDownPoint : null,
      docRef : props.docRef,
      graphParams : [],
      graphParam1 : "as",
      graphParam2 : "alts",
      graphParamSelect : [],
      landingsHidden : true,
      landingElement : [],
      landData : {},
      centerLineAlt : 5045,
      showCenterLine : false
    }
    this.toggleElements = this.toggleElements.bind(this)
    this.changeTime = this.changeTime.bind(this)
    this.calculateAltitudes = this.calculateAltitudes.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.generateGraphAlts = this.generateGraphAlts.bind(this)
    this.generateGraphs = this.generateGraphs.bind(this)
    this.changeGraphParam1 = this.changeGraphParam1.bind(this)
    this.changeGraphParam2 = this.changeGraphParam2.bind(this)
    this.changeCenterlineAlt = this.changeCenterlineAlt.bind(this)
  }
  
  componentDidMount(){
    //console.log(this.state.touchDownPoint)
   
    //this.generateGraphAlts()
    let a = this
    a.setState({drawHidden:true})
    var manuvers = []
    var splits = []
    if(isMobile) {
      //a.setState({endTime:1000})
    }
    /*
    for(var i = 0; i < this.state.manuverSplits.length; i ++){
      manuvers.push(<FormControlLabel value={i} control={<Radio />} label={this.state.manuverSplits[i][2]} />)
      if(i == this.state.manuverSplits.length - 1){
        a.setState({manuverElements:manuvers})
      }
    }
    */
    if(this.state.manuvers!=null){
    for(var i = 0; i < this.state.manuvers.length; i++){
      console.log(this.state.manuvers[i].touchDownPoint)
      splits.push([parseInt(this.state.manuvers[i].startTime),parseInt(this.state.manuvers[i].endTime),this.state.manuvers[i].name])
      manuvers.push(<FormControlLabel value={i} control={<Radio />} label={this.state.manuvers[i].name} />)
      if(i == this.state.manuvers.length-1){
        a.setState({manuverElements:manuvers},()=>a.setState({drawHidden:true}))
        a.setState({manuverSplits:splits})
        
      }
    }
  }
  this.state.docRef.get().then(function(doc){
    
    console.log(doc.data())
    let d = doc.data()
    console.log(Object.keys(d).length)
    var params = []
    var names = []
    var i = 0;

    console.log(doc.data().tdPoints)
    a.setState({landData : doc.data().tdPoints})

    for(var property in d){
      let b = a
      i++
      console.log(property)
      if(property != 'manuvers' && property != 'correctedAlts' && property != 'lats' && property !='longs' && property != 'flightPlan' && property != 'headings' && property != "tdPoints"){
        //console.log(d[property])
        params.push({'name':property,'data':d[property]})
        names.push(<MenuItem value={property}>{property}</MenuItem>)
      }
      if(i == Object.keys(d).length-1){
        a.setState({graphParams : d,graphParamSelect:names})
        a.generateGraphs()
        console.log(params)
      }
    }
  })
  //load Model


  }
  toggleElements(value){
    let a = this
    if(value == 0){
      let mH = !a.state.manuversHidden
      a.setState({manuversHidden:mH})
    }
    if(value == 1){
      let gH = !a.state.timelineHidden
      a.setState({timelineHidden:gH})
    }
    if(value == 2){
      let gH = !a.state.graphHidden
      a.setState({graphHidden:gH})
    }
    if(value ==3){
      let dH = !a.state.drawHidden
      a.setState({drawHidden:dH})
    }
    if(value == 4){
      let lH = !a.state.landingsHidden
      if(a.state.landingsHidden == true){
        a.setState({landingsHidden:lH, landingElement : <LandingDisplay landings = {this.state.landData}></LandingDisplay>})
      }else{
        a.setState({landingsHidden:lH, landingElement : []})
      }
      
    }
    if(value == 5){
      console.log(this.state.showCenterLine)
      let cH = !a.state.showCenterLine
      a.setState({showCenterLine : cH})
    }
  }
  changeTime(event, newValue){
    this.setState({startTime : newValue[0]})
    this.setState({endTime : newValue[1]})
    this.calculateAltitudes(newValue[0],newValue[1])
    //this.generateGraphAlts()
    this.generateGraphs()
  }
  calculateAltitudes(start, end){
    var range = []
    for(var i = start; i < end; i++){
      range.push(this.state.alts[i])
      if(i == end-1){
        console.log(Math.max(...range))
        console.log(Math.min(...range))
      }
    }
  }

  generateGraphAlts(){
   // this.generateGraphs()
    console.log(this.state.as)
    console.log("GENERATING GRAPH ALTS")
    let a = this
    let workingAlts = this.state.alts
    let workingAS = this.state.as
    let altData = []
    let carrySpeed = workingAS[this.state.startTime]
    for(var i = this.state.startTime; i < this.state.endTime; i ++){
     // console.log(workingAlts[i])
     altData.push({
      alts: parseInt(workingAlts[i]),
      as: parseInt(workingAS[i])
    })
    if(i == a.state.endTime - 1){
      a.setState({altData:altData})
      //console.log(workingAlts)
    }
     /*
     if(workingAS[i]<carrySpeed*1.5){
      altData.push({
        alts: parseInt(workingAlts[i]),
        as: parseInt(workingAS[i])
      })
      carrySpeed = workingAS[i]
    }else{
      altData.push({
        alts: parseInt(workingAlts[i]),
        as: carrySpeed
      })
    }
      if(i == a.state.endTime - 1){
        a.setState({altData:altData})
        //console.log(workingAlts)
      }
      */
    }
  }
  generateGraphs(){
    console.log(this.state.as)
    console.log("GENERATING GRAPHSS")
    let a = this
    let workingAlts = this.state.alts
    let workingAS = this.state.as
    let graphData = []
    
    for(var i = this.state.startTime; i<this.state.endTime; i ++){
      
      graphData.push({
        [a.state.graphParam1] : a.state.graphParams.[a.state.graphParam1][i],
        [a.state.graphParam2] : a.state.graphParams.[a.state.graphParam2][i]
      })
      if(i == this.state.endTime - 1){
        a.setState({altData:graphData})
        console.log(graphData)
      }
    }




    for(var i = 0; i < this.state.graphParams.length; i++){
      console.log(a.state.graphParams[i]['name'])
      if(a.state.graphParams[i]['name'] == a.state.graphParam1){

      }
    }
  }
  handleChange(event){
    console.log('CHANGED MANUVE')
    console.log(event.target.value)
    let times = this.state.manuverSplits[event.target.value]
    console.log(this.state.touchDownPoint)
    //this.setState({startTime : times[0]}, this.setState({endTime : times[1]}))
    this.setState({startTime : times[0],endTime : times[1], selectedRadio : event.target.value,touchDownPoint:this.state.manuvers[event.target.value].touchDownPoint},()=>this.setState({startTime : times[0],endTime : times[1], selectedRadio : event.target.value,touchDownPoint:this.state.manuvers[event.target.value].touchDownPoint}))
    
    //this.generateGraphAlts()
    this.generateGraphs()
    //this.setState({selectedRadio : event.target.value})
  }

  changeGraphParam1(event){
    
    console.log(event.target.value)
    this.setState({graphParam1:event.target.value},()=>this.generateGraphs())
  }
  changeGraphParam2(event){
    
    console.log(event.target.value)
    this.setState({graphParam2:event.target.value},()=>this.generateGraphs())
  }
  changeCenterlineAlt(event){
    this.setState({centerLineAlt : event.target.value})
  }

    _clear = () => {
      this._sketch.clear();
    };

  render(){
    return(
      <div>
        <div hidden = {this.state.drawHidden} style = {{zIndex : 4, position: 'fixed'}}>
        <Card style={{marginTop:'50px', textAlign:'center'}}>
            <h3>Draw</h3>
            <Button onClick={this._clear}>Clear</Button>
          </Card>
         <SketchField width='100vw' 
                         height='90vh' 
                         tool={Tools.Pencil} 
                         lineColor={this.state.lineColor}
                         lineWidth={3}  ref={c => (this._sketch = c)}/>
        </div>
        
        <Grid container>
          <Grid item xs = {2}>
            <Paper style = {{zIndex : 2, position : 'absolute', marginTop : '15vh'}} hidden={this.state.manuversHidden}>
            <Paper elevation={4}>
             <h3>Identified Maneuvers</h3>
              <RadioGroup aria-label="Manuvers" name="manuvers1" value={this.state.selectedRadio} onChange={this.handleChange}>
              {this.state.manuverElements}
              </RadioGroup>
            
          </Paper>
            </Paper>
          </Grid>
          <Grid item xs = {12}>
          <Paper style = {{zIndex : 1, position : 'absolute',marginTop : '85vh', marginBottom : '15vh', width : '100vw', textAlign:'center'}} hidden={this.state.timelineHidden}>
            <h3>Time Line</h3>
            <Slider style={{marginLeft:'2.5vw',marginRight:'2.5vw', width:'95vw'}} min = {1} max = {this.state.longs.length} onChange={(event, newValue) => {this.changeTime(event,newValue)}} value={[this.state.startTime,this.state.endTime]} valueLabelDisplay="auto" aria-labelledby="range-slider"/>
          </Paper>
          </Grid>

      <Grid item xs = {12}>
      <Paper style = {{zIndex : 1, position : 'absolute', marginTop : '5vh', }} hidden={this.state.graphHidden}>
      <LineChart width={window.innerWidth} height={250} data={this.state.altData} margin={{top: 5,right: 30,left: 20,bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis yAxisId = "left"  type="number" domain={['auto', 'auto']}/>
      <YAxis yAxisId="right" orientation="right" />
      <Tooltip />
      <Legend />
      <Line
        type="linear"
        dataKey={this.state.graphParam1}
        stroke="#8884d8"
        yAxisId="right"
        dot = {false}
      />
      <Line type="monotone" dataKey={this.state.graphParam2} stroke="#82ca9d" yAxisId="left" dot = {false}/>
    </LineChart>
    <Grid container style = {{alignItems:'center'}}>
    <p>Graph Parameter 1  </p>
    <Select labelId="Graph Parameter 1" id = "graphParam1" value={this.state.graphParam1} onChange={this.changeGraphParam1}>
      {this.state.graphParamSelect}
    </Select>
    <p>     Graph Parameter 2 </p>
    <Select labelId="Graph Parameter 2" id = "graphParam2" value={this.state.graphParam2} onChange={this.changeGraphParam2}>
      {this.state.graphParamSelect}
    </Select>
    </Grid>
      </Paper>
        </Grid>

          <CesiumMap style = {{zIndex : 1}} showCenterLine = {this.state.showCenterLine} centerLineAlt = {this.state.centerLineAlt} touchDownPoint={this.state.touchDownPoint} lats = {this.state.lats} longs={this.state.longs} alts={this.state.alts} startTime = {this.state.startTime} endTime = {this.state.endTime} as = {this.state.as} gs = {this.state.gs} headings = {this.state.headings}/>

        </Grid>
        <BottomNavigation onChange={(event, newValue) => {this.toggleElements(newValue)}} showLabels style = {{zIndex : 4, position: 'fixed', bottom: '0', width : '100vw', backgroundColor : 'grey'}}>
         
          <BottomNavigationAction label="Maneuvers" icon={<CodeIcon />} />
          <BottomNavigationAction label="Timeline" icon={<TimelineIcon />} />
          <BottomNavigationAction label="Charts" icon={<MultilineChartIcon />} />
          <BottomNavigationAction label="Draw" icon={<CreateIcon />} />
          <BottomNavigationAction label="Landings" icon={<NotesIcon />} />
          <BottomNavigationAction label="Flight Team" icon={<FlightLandIcon />} />
        </BottomNavigation>

        <Paper style = {{zIndex : 1, position : 'absolute', marginTop : '475px', marginLeft : '10px', width : '305px'}} hidden = {this.state.landingsHidden}>
          {this.state.landingElement}
        </Paper>

        <Paper style = {{zIndex : 5, position : 'absolute', marginTop : '475px', marginLeft : '90vw', alignItems : 'center'}} hidden = {!this.state.showCenterLine}>
          <h5>KPRC 21R Centerline</h5>
          <h5>Height</h5>
          <TextField defaultValue = {this.state.centerLineAlt} onChange={this.changeCenterlineAlt}></TextField>
        </Paper>


      </div>
    )
  }
}
export default DebreifPage
