import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DebreifPage from './debreifPage'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
class LoadFromURL extends Component{
    constructor(props){
        super(props)
        this.state = {
            user : props.user,
            id : props.id,
            loading : true,
            debreif : [],
        }
    }
    componentDidMount(){
        console.log('Mounting Load')
        const db = firebase.firestore();
        const docRef = db.collection(this.props.user).doc('flights').collection('flights').doc(this.props.id)
        let a = this
        
        docRef.get().then(function(doc){
            if(doc.exists){
            console.log("doc exists")
            let lats = doc.data().lats
            let longs = doc.data().longs
            let alts = doc.data().alts
            var gs = doc.data().gs
            var as = doc.data().as
            var headings = doc.data().headings
            var correctedAlts = doc.data().correctedAlts
            var manuvers = doc.data().manuvers
            a.setState({loading:false,uploadPopUpOpen:false,debreif:<DebreifPage docRef={docRef} manuvers={manuvers} alts={correctedAlts} longs={longs} lats={lats} as={as} gs={gs} headings={headings} correctedAlts={correctedAlts}></DebreifPage>,currentPage:0,pageDesc:"Debreif"})
            }else{
                a.setState({loading:false,uploadPopUpOpen:false,debreif:<h1>Error : Flight Not Found</h1>,currentPage:0,pageDesc:"Debrief"})
            }
        })
    }
    render(){
        return(
            <div>
                <AppBar>
            <Toolbar>
              <Typography variant="h6">
                {this.state.user + " "}
                <Moment unix>{this.state.id}</Moment>
              </Typography>
            </Toolbar>
          </AppBar>
                 <Backdrop open={this.state.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {this.state.debreif}
            </div>
        )
    }
}
export default LoadFromURL