import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper'

class ManuverCard extends React.Component {
  render(){
    return(
      <Paper elevation = {3} style = {{textAlign : 'center'}}>
        <h3>Steep Turn</h3>
        <h4>Starting Altitude : 8500ft</h4>
        <h4>Ending Altitude : 8480ft</h4>
        <h4 style = {{color : 'green'}}>Recomended Grade : G</h4>
      </Paper>
    )
  }
}
export default ManuverCard
