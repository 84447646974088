import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    uv: 1000,
    amt: 0
  },
  {

    uv: 3000,
    amt: 1
  },
  {

    uv: 2000,
    amt: 2
  },
  {

    uv: 2780,
    amt: 3
  },
  {

    uv: 1890,
    amt: 4
  },
  {

    uv: 2390,
    amt: 5
  },
  {

    uv: 3490,
    amt: 6
  }
];

class TestGraph extends Component {
  constructor(props){
    super(props)
    this.state = {
      alts : props.alts,
      altData : [],
      startTime : props.startTime,
      endTime : props.endTime
    }
  }
  componentDidMount(){
    let a = this
    let workingAlts = this.state.alts
    let altData = []
    for(var i = this.state.startTime; i < this.state.endTime; i ++){
      console.log(workingAlts[i])
      altData.push({
        alts: parseInt(workingAlts[i]),
        amt: 0
      })
      if(i == a.state.endTime - 1){
        a.setState({altData:altData})
        console.log(workingAlts)
      }
    }
  }
  componentDidUpdate(){

  }
  render(){
  return (
    <LineChart
      width={1920}
      height={250}
      data={this.state.altData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="alts" stroke="#82ca9d" />
    </LineChart>
  );
  }
}

export default TestGraph