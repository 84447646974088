import React, { Component } from 'react';
import { Line, SteppedLine } from 'react-lineto';
import Select from '@material-ui/core/Select'
import { MenuItem  } from '@material-ui/core';
import * as geolib from 'geolib';
class LandingDisplay extends Component{
    constructor(props){
        super(props)
        this.state = {
            landingLat : 34.665271,
            landingLon : -112.411119,
            //landingPoint : {"lat":34.665056, "lon" : -112.411244},
            landingPoint : props.landings[0],
            centRight : [34.665329,-112.411193],
            centLeft : [34.665211, -112.411038],
            rearRight : [34.665534,-112.410976],
            rearLeft : [34.665425, -112.410827],
            frontRight: [34.664917, -112.411633],
            frontLeft : [34.664802, -112.411483],
            startRef : [34.665478, -112.410896],
            rearCenter : [34.665479, -112.410900],
            rwyWidth : 60,
            xPoint : 0,
            yPoint : 0,
            rwyHeading : 221.09,
            centerLine : 0,
            distanceFromPoint : 0,
            latTemp : 0,
            lonTemp : 0,
            //landings : [{"lat":34.665056, "lon":-112.411244},{"lat":34.665275, "lon":-112.411118}],
            landings : props.landings,
            selectedLanding : 0,
            landingMenuItems : [],
            hidden : false,
            zIndex : props.zIndex

        }
        this.latBoxChanged = this.latBoxChanged.bind(this)
        this.lonBoxChanged = this.lonBoxChanged.bind(this)
        this.updatePoint = this.updatePoint.bind(this)
        this.calculatePoint = this.calculatePoint.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount(){
        let a = this

        var xPix = 0
        var yPix = 0
        let radius = geolib.getPreciseDistance(
            { latitude: this.state.rearCenter[0], longitude: this.state.rearCenter[1] },
            { latitude: this.state.landingPoint.lat, longitude: this.state.landingPoint.lon }
        )*3.28
        let theta = geolib.getGreatCircleBearing(
            { latitude: this.state.rearCenter[0], longitude: this.state.rearCenter[1] },
            { latitude: this.state.landingPoint.lat, longitude: this.state.landingPoint.lon }
        )-this.state.rwyHeading
        xPix = 310-(radius*Math.cos(theta*Math.PI/180))
        yPix = 540-((radius*Math.sin(theta*Math.PI/180)))
        this.setState({xPoint:xPix,yPoint:yPix})
        var menuItemCarry = []
        for(var i = 0; i < this.state.landings.length; i = i + 1){
            menuItemCarry.push(<MenuItem value={i}>Landing {i+1}</MenuItem>)
            if(i == this.state.landings.length - 1){
                a.setState({landingMenuItems : menuItemCarry})
            }
        }

    }
    calculatePoint(){
        console.log('recalc point')
        var xPix = 0
        var yPix = 0
        let radius = geolib.getPreciseDistance(
            { latitude: this.state.rearCenter[0], longitude: this.state.rearCenter[1] },
            { latitude: this.state.landingPoint.lat, longitude: this.state.landingPoint.lon}
        )*3.28
        let theta = geolib.getGreatCircleBearing(
            { latitude: this.state.rearCenter[0], longitude: this.state.rearCenter[1] },
            { latitude: this.state.landingPoint.lat, longitude: this.state.landingPoint.lon }
        )-this.state.rwyHeading
        xPix = 310-(radius*Math.cos(theta*Math.PI/180))
        yPix = 540-((radius*Math.sin(theta*Math.PI/180)))
        this.setState({xPoint:xPix,yPoint:yPix})
    }
    latBoxChanged(event){
        console.log(event.target.value)
        this.setState({latTemp : event.target.value})
    }
    lonBoxChanged(event){
        this.setState({lonTemp : event.target.value})
    }
    updatePoint(){
        console.log('updating point!')
        let lat = this.state.latTemp
        let lon = this.state.lonTemp
        this.setState({landingPoint : [lat,lon]}, ()=>this.calculatePoint())
    }
    handleChange(event){
        console.log(event.target.value)
        
        let newLanding = this.state.landings[event.target.value]
        this.setState({landingPoint : newLanding,selectedLanding : event.target.value}, ()=>this.calculatePoint())
    }
    render(){
        return(<div className = "masterDiv">

        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.selectedLanding} label="Landings" onChange={this.handleChange}>
            {this.state.landingMenuItems}
        </Select>
            <Line x0={10} y0={510} x1={310} y1={510} zIndex = {this.state.zIndex}/>
            <Line x0={10} y0={510} x1={10} y1={570} zIndex = {this.state.zIndex}/>
            <Line x0={10} y0={570} x1={310} y1={570} zIndex = {this.state.zIndex}/>
            <Line x0={310} y0={510} x1={310} y1={570} zIndex = {this.state.zIndex}/> 
            <Line x0={210} y0={510} x1={210} y1={570} borderStyle={'dashed'} zIndex = {this.state.zIndex}/>
            <Line x0={110} y0={510} x1={110} y1={570} borderStyle={'dashed'} zIndex = {this.state.zIndex}/>
            

            <Line x0={290} y0={540} x1={310} y1={540} zIndex = {this.state.zIndex}/>
            <Line x0={90} y0={540} x1={210} y1={540} zIndex = {this.state.zIndex}/>
            
            <Line x0={this.state.xPoint} y0={this.state.yPoint-3} x1={this.state.xPoint} y1={this.state.yPoint+3} zIndex = {this.state.zIndex}/>
            <Line x0={this.state.xPoint-3} y0={this.state.yPoint} x1={this.state.xPoint+2.5} y1={this.state.yPoint} zIndex = {this.state.zIndex}/>
            
            <h5 style = {{marginTop : 100}}>Centerline : {this.state.yPoint - 540} ft</h5>
            <h5>Distance From Point : {(this.state.xPoint - 310 + 100)*-1} ft</h5>
            
        </div>)

    }
}

export default LandingDisplay