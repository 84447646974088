import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css'
import "./styles.css";
import "react-mosaic-component/react-mosaic-component.css";
import App from './App';
import Login from './login'
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Director from './router'
const firebaseConfig = {
  apiKey: "AIzaSyDWXO5g95KnY1L4z6mfqzdW1MnQZGE1cQM",
    authDomain: "sfsdebreif.firebaseapp.com",
    projectId: "sfsdebreif",
    storageBucket: "sfsdebreif.appspot.com",
    messagingSenderId: "16574895524",
    appId: "1:16574895524:web:9f7591e86ee3b59dd7dd31",
    measurementId: "G-T1EJVDZBC0"
};
firebase.initializeApp(firebaseConfig)
//export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();


ReactDOM.render(
  <React.StrictMode>
    <Director />
  </React.StrictMode>,
  document.getElementById('root')
);
firebase.auth().onAuthStateChanged(function(user){
  if(user){
    ReactDOM.render(
      <React.StrictMode>
        <Director />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }else{
    ReactDOM.render(
      <React.StrictMode>
        <Login />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})
/*
if(firebase.auth().currentUser!=null){
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
}else{

}

firebase.auth().onAuthStateChanged(function(user){
  if(user){
    ReactDOM.render(
      <React.StrictMode>
        <Director />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }else{
    ReactDOM.render(
      <React.StrictMode>
        <Login />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
