import React, { Component } from 'react';
import { Cartesian3, Color, Material, createWorldTerrain, Ion, IonResource,TimeIntervalCollection,TimeInterval, SampledPositionProperty, JulianDate, PositionProperty} from "cesium";
import { Clock, Viewer, Entity, GeoJsonDataSource, Camera, CameraFlyTo, PolygonGraphics, WallGraphics,Cesium3DTileset, Scene, Primitive, Globe, Model,Billboard,BillboardCollection,Polyline,PolylineCollection} from "resium";
import { getPreciseDistance, getPathLength } from 'geolib';
import ManuverBar from './manuverBar'
import { ThreeSixtySharp } from '@material-ui/icons';
import ModelGraphics from 'cesium/Source/DataSources/ModelGraphics';
Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMjdlZmZlMi1hMDQyLTQ3M2QtYjliZS1iM2M3Y2JmZWNhMDkiLCJpZCI6MzU4NjgsImlhdCI6MTYwMjY1MzU5NX0.YXmCyWS_MA7glwo3MYbaAOhOO3QRHRugj1GuIiOs2Hc";
let groundSpeeds = []
let geoJSON = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -112.41168022155762,
            34.661663735135384
          ],
          [
            -112.4282455444336,
            34.646766246519114
          ],
          [
            -112.4351978302002,
            34.643871163172555
          ],
          [
            -112.44146347045898,
            34.64485973958312
          ],
          [
            -112.44412422180176,
            34.65064973615208
          ],
          [
            -112.43785858154297,
            34.65693354309436
          ],
          [
            -112.43047714233397,
            34.66808789192226
          ],
          [
            -112.41494178771973,
            34.672888035547786
          ],
          [
            -112.40035057067871,
            34.67302921203181
          ]
        ]
      }
    }
  ]
}
var airspeeds = []

const terrainProvider = createWorldTerrain();


let aC = IonResource.fromAssetId(486484)

let extended21L = {

}

class CesiumMap extends Component {
    
    //Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMjdlZmZlMi1hMDQyLTQ3M2QtYjliZS1iM2M3Y2JmZWNhMDkiLCJpZCI6MzU4NjgsImlhdCI6MTYwMjY1MzU5NX0.YXmCyWS_MA7glwo3MYbaAOhOO3QRHRugj1GuIiOs2Hc";
    constructor(props){
      super(props)
      this.state = {
        position : Cartesian3.fromDegrees(-112.4207652, 34.65215367, 4883.202256),
        points : [],
        geoJ : {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                "coordinates": [
                  [
                    -112.41168022155762,
                    34.661663735135384
                  ],
                  [
                    -112.4282455444336,
                    34.646766246519114
                  ],
                  [
                    -112.4351978302002,
                    34.643871163172555
                  ],
                  [
                    -112.44146347045898,
                    34.64485973958312
                  ],
                  [
                    -112.44412422180176,
                    34.65064973615208
                  ],
                  [
                    -112.43785858154297,
                    34.65693354309436
                  ],
                  [
                    -112.43047714233397,
                    34.66808789192226
                  ],
                  [
                    -112.41494178771973,
                    34.672888035547786
                  ],
                  [
                    -112.40035057067871,
                    34.67302921203181
                  ]
                ]
              }
            }
          ]
        },
        GJ : [],
        walls : [],
        lats : props.lats,
        longs : props.longs,
        alts : props.alts,
        as : props.as,
        gs : props.gs,
        headings : props.headings,
        startTime : props.startTime,
        endTime : props.endTime,
        intro : [],
        showSpeedDots : false,
        acLocation : 1,
        playAircraft : true,
        pp : new SampledPositionProperty(),
        start : new JulianDate.fromIso8601("2020-03-09T23:10:00Z"),
        stop : new JulianDate.fromIso8601("2020-03-09T23:10:00Z"),
        speedDots : props.speedDots,
        touchDownPoint : [],//props.touchDownPoint,
        TDP : Cartesian3.fromDegrees(0,0,0),
        previousPoint : null,
        polyLinePos : [],
        hasSelectedStart : false, 
        tempStart : 0,
        tempEnd : 0,
        lines : [],
        airplane3d : [],
        centerLineAlt : 5045,
        centerLine : [],
        showCenterLine : props.showCenterLine
      }
      this.generateEntities = this.generateEntities.bind(this)
      this.generateGEOJSON = this.generateGEOJSON.bind(this)
      this.generateWalls = this.generateWalls.bind(this)
      this.calculateTotalDistance = this.calculateTotalDistance.bind(this)
      this.generateACPath = this.generateACPath.bind(this)
      this.generatePolyLine = this.generatePolyLine.bind(this)
      this.clickToSelect = this.clickToSelect.bind(this)
      this.updateEntities = this.updateEntities.bind(this)
      this.generateCenterline = this.generateCenterline.bind(this)
    }
    componentDidMount(){
     
     
     let a = this
     if(this.state.showSpeedDots){
      this.generateEntities(this.props.startTime, this.props.endTime)
      //this.generatePolyLine(this.props.startTime, this.props.endTime)
     }
     //this.generateGEOJSON()
     this.generateWalls(this.state.startTime,this.state.endTime)
     this.generateLines(this.state.startTime,this.state.endTime)
     let latMax = Math.max(...this.state.lats)
     let latMin = Math.min(...this.state.lats)
     let longMax = Math.max(...this.state.longs)
     let longMin = Math.min(...this.state.longs)
    // console.log(((latMax-latMin)/2)+latMin)
    // console.log(((longMax-longMin)/2)+longMax)
     let lat = ((latMax-latMin)/2)+latMin
     let long = ((longMax-longMin)/2)+longMin
     let p = Cartesian3.fromDegrees(long,lat,25000)
     this.setState({position:p})
     this.setState({intro : <CameraFlyTo duration={5} destination={p} />})
     this.calculateTotalDistance()
     console.log('TOUCH DOWN POINT')
     console.log(this.state.touchDownPoint)

     //this.generateCenterline()


    }
    componentDidUpdate(){
      
      this.state.walls = []
      this.state.points = []
      this.state.lines = []
      //this.state.centerLine = []
      this.generateWalls(this.props.startTime,this.props.endTime)
      this.generateLines(this.props.startTime,this.props.endTime)
      if(this.state.showSpeedDots){
      this.generateEntities(this.props.startTime, this.props.endTime)
     // this.generatePolyLine(this.props.startTime, this.props.endTime)
      }
     // console.log(this.props.startTime)
     // console.log(this.props.endTime)
     console.log('TOUCH DOWN POINT')
      console.log(this.props.touchDownPoint)
      if(this.props.touchDownPoint!=null){
        this.showTouchDownPoint(this.props.touchDownPoint)
      }
      this.generateCenterline(this.props.showCenterLine,this.props.centerLineAlt)
    }
    showTouchDownPoint(point){
      if(point!=null && point!=this.state.previousPoint){
        this.setState({TDP:Cartesian3.fromDegrees(point[0],point[1],point[2]),previousPoint:point})
        this.setState({touchDownEnt: <Entity name={"Aircraft"} description="Aircraft"  position={Cartesian3.fromDegrees(point[1],point[0],point[2]/3.28084)} point={{ pixelSize: 25, color: Color.BLUE }} show = {true}>
        </Entity>})
     }
    }
    updateEntities(){
      console.log('updating entitites' + this.state.tempStart)
      //this.generateWalls(start,end)
      this.points = []
      this.generateEntities(this.state.tempStart,this.state.endTime)
    }
    clickToSelect(point){
      console.log("Point Selected "+point + ' ' + this.state.hasSelectedStart)
      let a = this
      if(!this.state.hasSelectedStart){
        console.log('Setting Start')
        a.setState({tempStart:point},this.updateEntities)
        
      }else{
        //a.setState({endTime:point},()=>this.componentDidUpdate())
      }
    }
    generateEntities(start, end){
      let a = this
      var dotColor = Color.WHITE
      var positions = []
      for(var i = start; i < end-1; i ++){
        console.log(i)
        let p = a.state.points
        //console.log('KIAS : ' + knotsAS + ' KGS : ' + knotsGS + ' Meters : ' + meters)
        let time = i
        if(this.state.as[i]<20){
          dotColor = Color.WHITE
        }else{
          if(this.state.as[i]>=48 && this.state.as[i]<65){
            dotColor = Color.RED
          }else{
            if(this.state.as[i]>=65 && this.state.as[i]<72){
              dotColor = Color.YELLOW
            }else{
              if(this.state.as[i]>=72 && this.state.as[i]<120){
                dotColor = Color.GREEN
              }
            }
          }
        }


        p.push(<Entity
          description= {time}
          position={Cartesian3.fromDegrees(this.state.longs[i], this.state.lats[i], (this.state.alts[i]/3.28084))}
          point={{ pixelSize: 10, color: dotColor }}
          onClick={()=>this.clickToSelect(time)}
          />)
          if(time == this.state.alts.length -1){
           //a.setState({points:a})
          }
      }
    }
    calculateTotalDistance(){
      var points = []
      for(var i =0; i < this.state.longs.length; i ++){
        points.push({ latitude: this.state.longs[i], longitude: this.state.lats[i]})
        if(i==this.state.longs.length - 1){
          console.log(getPathLength(points)*0.000539957)
          
        }
      }
    }
    generateWalls(start, end){

      let a = this
      let w = this.state.walls
      let latMax = Math.max(...this.state.lats)
      let latMin = Math.min(...this.state.lats)
      let longMax = Math.max(...this.state.longs)
      let longMin = Math.min(...this.state.longs)
      let min = Cartesian3.fromDegrees(longMax,latMax,3048)
      let max = Cartesian3.fromDegrees(longMin,latMin,3048)
      const positions = Cartesian3.fromDegreesArray([longMax,latMax,3048, longMin,latMin, 3048])
      var wallColor = Color.WHITE.withAlpha(0.5)
      for(var i = start; i < end-1;i++){
        if(this.state.as[i]<20){
          wallColor = Color.WHITE.withAlpha(0.5)
        }else{
          if(this.state.as[i]>=48 && this.state.as[i]<65){
            wallColor = Color.RED.withAlpha(0.5)
          }else{
            if(this.state.as[i]>=65 && this.state.as[i]<72){
              wallColor = Color.YELLOW.withAlpha(0.5)
            }else{
              if(this.state.as[i]>=72 && this.state.as[i]<120){
                wallColor = Color.GREEN.withAlpha(0.5)
              }
            }
          }
        }
        w.push( <Entity name={"PolygonGraphics"+i} description="PolygonGraphics!!">
        <WallGraphics
          positions={Cartesian3.fromDegreesArrayHeights([this.state.longs[i-1],this.state.lats[i-1],(this.state.alts[i-1]/3.28084+5),this.state.longs[i],this.state.lats[i],(this.state.alts[i]/3.28084+5)])}
          minimumHeights={[0, 0]}
          material={wallColor}
          outline={false}
          outlineColor={wallColor}
          outlineWidth = {10}
          >
          </WallGraphics>
        </Entity>)
        if(i == this.state.longs.length -1){
          //a.setState({walls:w})
        }
      }
    }

    generateExtendedCenterline(start,end){

    }

    generateLines(start,end){
      let a = this
      let l = this.state.lines
      let latMax = Math.max(...this.state.lats)
      let latMin = Math.min(...this.state.lats)
      let longMax = Math.max(...this.state.longs)
      let longMin = Math.min(...this.state.longs)
      var lineColor = Color.WHITE.withAlpha(0.5)
      
      //lineMaterial.uniforms.color = Color.WHITE
      for(var i = start;i<end-1;i++){
        var lineMaterial = Material.fromType('Color')
        if(this.state.as[i]<20){
          lineMaterial.uniforms.color = Color.WHITE
        }else{
          if(this.state.as[i]>=48 && this.state.as[i]<65){
            lineMaterial.uniforms.color = Color.RED
          }else{
            if(this.state.as[i]>=65 && this.state.as[i]<72){
              lineMaterial.uniforms.color = Color.YELLOW
            }else{
              if(this.state.as[i]>=72){
                lineMaterial.uniforms.color = Color.GREEN
              }
            }
          }
        }
        l.push( 
          
        <Polyline
          positions={Cartesian3.fromDegreesArrayHeights([this.state.longs[i-1],this.state.lats[i-1],(this.state.alts[i-1]/3.28084+5),this.state.longs[i],this.state.lats[i],(this.state.alts[i]/3.28084+5)])}
          material={lineMaterial}
          width = {5.0}
          >
          </Polyline>
          
        )
      }

    }






    generateACPath(){
      /*
      let start = JulianDate.fromIso8601("2020-03-09T23:10:00Z");
      let stop = JulianDate.addSeconds(start, this.state.longs.length - 1, new JulianDate());
      console.log(stop)
      let pp = new SampledPositionProperty()
      for(var i = 1; i < this.state.longs.length -1 ; i++){
        var time = JulianDate.addSeconds(start, i*1, new JulianDate());
        var position = Cartesian3.fromDegrees(this.state.longs[i], this.state.lats[i], (this.state.alts[i]/3.28084)-26.4846)
        pp.addSample(time,position)
        if(i == this.state.longs.length -1){
          this.setState({pp:pp,start:start,stop:stop})
        }
      }
      */
    }

    generateGEOJSON(){

      let a = this
      let coords = []
      for(var i = 0; i < this.state.longs.length-1; i++){
        coords.push([this.state.longs[i],this.state.lats[i]])
        geoJSON.features[0].geometry.coordinates = coords
        if(i == this.state.longs.length - 1){
          var g = a.state.geoJ
          g.coordinates = coords
          //console.log(g)
          a.setState({GJ:<GeoJsonDataSource data = {geoJSON}>

          </GeoJsonDataSource>})
        }
      }

    }

    generateTouchDownPoint(){

    }
    generatePolyLine(start, end){
      let a = this
      let positions = []
      for(var i = start; i < end -1; i++){
        positions.push(Cartesian3.fromDegrees(a.state.longs[i], a.state.lats[i], (a.state.alts[i]/3.28084)))
        if(i == end - 2){
          a.setState({polyLinePos:positions})
        }

      }
    }

    generateCenterline(showCenterLine,centerLineAlt){
      console.log('generating center line '  + showCenterLine + ' ' + centerLineAlt)
      let c = this.state.centerline
      this.state.centerLine = []
      if(!showCenterLine){
      this.state.centerLine.push(<div><PolylineCollection>
            <Polyline positions={Cartesian3.fromDegreesArrayHeights([-112.395480405,34.68,(centerLineAlt/3.28),-112.437959458,34.64,(centerLineAlt/3.28)])} width = {5.0}></Polyline>
            </PolylineCollection>

            <Entity name={"centerLineWalls"} description="PolygonGraphics!!">
        <WallGraphics
          positions={Cartesian3.fromDegreesArrayHeights([-112.395480405,34.68,(centerLineAlt/3.28),-112.437959458,34.64,(centerLineAlt/3.28)])}
          minimumHeights={[0, 0]}
          
          outline={false}
          
          outlineWidth = {10}
          >
          </WallGraphics>
        </Entity></div>)
      }
      
        //this.setState({centerLine : c})
    }
    

    /*

     <PolylineCollection>
            <Polyline positions={Cartesian3.fromDegreesArrayHeights([-112.45275020599365,34.62678105123197,(6000/3.28),-112.3915958404541,34.68347560408199,(6000/3.28)])} width = {5.0}></Polyline>
            <Polyline positions={Cartesian3.fromDegreesArrayHeights([-112.42682967334986,34.66148806760214,(6000/3.28),-112.41648741066454,34.671193898971,(6000/3.28)])} width = {5.0}></Polyline>
            </PolylineCollection>

            <Entity name={"centerLineWalls"} description="PolygonGraphics!!">
        <WallGraphics
          positions={Cartesian3.fromDegreesArrayHeights([-112.45275020599365,34.62678105123197,(6000/3.28),-112.3915958404541,34.68347560408199,(6000/3.28)])}
          minimumHeights={[0, 0]}
          
          outline={false}
          
          outlineWidth = {10}
          >
          </WallGraphics>
        </Entity>
        */

    render(){
        return(
          <div>


          <Viewer full terrainProvider={terrainProvider} position={this.state.position} animation={false} geocoder= {false} selectionIndicator = {false} requestRenderMode={false} infoBox={false} timeline={false} homeButton={false} navigationHelpButton={false} fullscreenButton={false} vrButton={false} projectionPicker={false} shouldAnimate={false} navigationInstructionsInitiallyVisible={false}  navigationHelpButton = {false} baseLayerPicker={false} sceneModePicker={true}>
            {this.state.points}
            {this.state.GJ}
            {this.state.walls}
            {this.state.airplane3d}
            <PolylineCollection>
              {this.state.lines}
            </PolylineCollection>
           


            <Entity position={Cartesian3.fromDegrees(0,0,10000)} model={IonResource.fromAssetId(486484)}>
           
            </Entity>

            {this.state.centerLine}
            
            
            <BillboardCollection>

        <Billboard key={5} color={Color.CYAN} position={new Cartesian3(0.0, 0.0, 1000000.0)} />

            </BillboardCollection>
            <Scene>
            {this.state.touchDownEnt}
          <Globe depthTestAgainstTerrain={true}></Globe>
          <Primitive>
          
        </Primitive>
      </Scene>
            {this.state.intro}

          </Viewer>

          </div>
        )
    }

}

export default CesiumMap
