import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import DebreifPage from './debreifPage'
import Modal from '@material-ui/core/Modal';
import { CSVReader } from 'react-papaparse';
import Card from '@material-ui/core/Card'
import { getPreciseDistance, getPathLength, getRhumbLineBearing } from 'geolib';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import CloudDebreif from './cloudDebreif'
import FlightSelection from './flightSelection'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MapSharpIcon from '@material-ui/icons/MapSharp';
import FlightTakeoffSharpIcon from '@material-ui/icons/FlightTakeoffSharp';
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {isMobile} from 'react-device-detect';
import seerLogo from "./Seer_logo.png";

const egm96 = require("./egm1996Port");

class MyResponsiveGrid extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      uploadPopUpOpen : false,
      sharePopUpOpen : false,
      rawData : [],
      lats : [],
      longs : [],
      alts : [],
      gs : [],
      as : [],
      headings : [],
      debreif : [],
      currentPage : 1,
      lastID : "",
      drawerOpen : false,
      pageDesc : "Flights",
      loading : false,
      isMobile : false
    }
    this.togglePopUp = this.togglePopUp.bind(this)
    this.handleOnDrop = this.handleOnDrop.bind(this)
    this.handleOnError = this.handleOnError.bind(this)
    this.handleOnRemoveFile = this.handleOnRemoveFile.bind(this)
    this.processData = this.processData.bind(this)
    this.processData2 = this.processData2.bind(this)
    this.processData3 = this.processData3.bind(this)
    this.loadFromCloud = this.loadFromCloud.bind(this)
    this.cloudDocUpdate = this.cloudDocUpdate.bind(this)
    this.loadFromCloud = this.loadFromCloud.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleShare = this.toggleShare.bind(this)
  }
  componentDidMount(){
    this.setState({debreif:<FlightSelection debreifFunc={this.loadFromCloud}></FlightSelection>})
    if(isMobile) {
      //this.setState({isMobile:true})  
    }
  }
  togglePopUp(){
    this.setState({uploadPopUpOpen:!this.state.uploadPopUpOpen})
  }
  handleOnDrop = (data) => {
    this.setState({debreif:[]})
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
    this.setState({rawData:data})
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };
  processData(){
    let a = this
    let raw = this.state.rawData
    var lats = []
    var longs = []
    var alts = []
    for(var i = 9; i < raw.length; i++){
      //console.log(parseFloat(raw[i].data[1]))
      
      alts.push(parseFloat(raw[i].data[1]))
      lats.push(parseFloat(raw[i].data[2]))
      longs.push(parseFloat(raw[i].data[3]))
      if(i == raw.length-1){
        a.setState({lats:lats})
        a.setState({longs:longs})
        a.setState({alts:alts})
        a.setState({uploadPopUpOpen:false})
        a.setState({debreif:<DebreifPage alts={alts} longs={longs} lats={lats}></DebreifPage>})
      }
    }
  }
  processData2(){
    var db = firebase.firestore()
    let a = this
    let raw = this.state.rawData
    var lats = []
    var longs = []
    var alts = []
    var gs = []
    var as = []
    var headings = []
    var correctedAlts = []
    console.log(raw[1].data[1])
    for(var i = 10; i < raw.length; i++){
      //console.log(parseFloat(raw[i].data[1]))
      let meters = getPreciseDistance({latitude: raw[i-1].data[2], longitude: raw[i-1].data[3]},{latitude: raw[i].data[2], longitude: raw[i].data[3]})
      let knotsGS = meters*1.944
      let knotsAS = knotsGS - (knotsGS*(.02*((raw[i].data[1]/3.28084)/1000)))
      let heading = getRhumbLineBearing({latitude: raw[i-1].data[2], longitude: raw[i-1].data[3]},{latitude: raw[i].data[2], longitude: raw[i].data[3]})
      gs.push(knotsGS)
      as.push(knotsAS)
      headings.push(heading)
      alts.push(parseFloat(raw[i].data[1]))
      lats.push(parseFloat(raw[i].data[2]))
      longs.push(parseFloat(raw[i].data[3]))
      if(i == raw.length-1){
      a.setState({lats:lats, longs:longs,alts:alts,gs:gs,as:as,uploadPopUpOpen:false}, () => a.setState({debreif:<DebreifPage alts={alts} longs={longs} lats={lats} as={as} gs={gs} headings={headings}></DebreifPage>}))
      //db.collection('john.rees@seerflightsystems.com').doc(raw[1].data[1]).set({lats:lats,longs:longs,alts:alts,gs:gs,as:as,headings,headings,correctedAlts:correctedAlts})
      }
    }
  }
  processData3(){
    var db = firebase.firestore()
    let a = this
    let raw = this.state.rawData
    var lats = []
    var longs = []
    var alts = []
    var gs = []
    var as = []
    var headings = []
    var correctedAlts = []
    var groundAlts = []
    var landings = []
    console.log(raw[1].data[1])
    for(var i = 10; i < raw.length; i++){
      //console.log(parseFloat(raw[i].data[1]))
      let meters = getPreciseDistance({latitude: raw[i-1].data[2], longitude: raw[i-1].data[3]},{latitude: raw[i].data[2], longitude: raw[i].data[3]})
      let knotsGS = meters*1.944
      let knotsAS = knotsGS - (knotsGS*(.02*((raw[i].data[1]/3.28084)/1000)))
      let heading = parseInt(getRhumbLineBearing({latitude: raw[i-1].data[2], longitude: raw[i-1].data[3]},{latitude: raw[i].data[2], longitude: raw[i].data[3]}))
      gs.push(knotsGS)
      as.push(knotsAS)
      headings.push(heading)
      alts.push(parseInt(raw[i].data[1]))
      lats.push(parseFloat(raw[i].data[2]))
      longs.push(parseFloat(raw[i].data[3]))
      if(i == raw.length-1){
      //db.collection('john.rees@seerflightsystems.com').doc(raw[1].data[1]).update({lats:lats,longs:longs,alts:alts,gs:gs,as:as,headings,headings}).then(a.setState({lats:lats, longs:longs,alts:alts,gs:gs,as:as,uploadPopUpOpen:false}, () => this.loadFromCloud('1622825894')))
      //this.loadFromCloud('1622825894')
      db.collection('john.rees@seerflightsystems.com').doc('flights').collection('flights').doc(raw[1].data[1]).set({lats:lats,longs:longs,alts:alts,gs:gs,as:as,headings:[]})
      .then(()=>{a.setState({loading:true,lats:lats, longs:longs,alts:alts,gs:gs,as:as,uploadPopUpOpen:false}, () => this.cloudDocUpdate(raw[1].data[1]))})
        //this.cloudDocUpdate(raw[1].data[1])
    }
    }
  }

  cloudDocUpdate(id){
    let a = this
    axios.post('http://localhost:5000/api/docSet', {
      id: id,
    })
    .then(function (response) {
      console.log(response);
      if(response.status == 200){
        a.loadFromCloud(id)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  loadFromCloud(id){
    this.setState({loading:true,debreif:[]})
    const db = firebase.firestore();
    this.setState({lastID : id})
    const docRef = db.collection('john.rees@seerflightsystems.com').doc('flights').collection('flights').doc(id)
    let a = this
    docRef.get().then(function(doc){
        let lats = doc.data().lats
        let longs = doc.data().longs
        let alts = doc.data().alts
        var gs = doc.data().gs
        var as = doc.data().as
        var headings = doc.data().headings
        var correctedAlts = doc.data().correctedAlts
        var manuvers = doc.data().manuvers
        
        a.setState({loading:false,uploadPopUpOpen:false,debreif:<DebreifPage docRef={docRef} manuvers={manuvers} alts={correctedAlts} longs={longs} lats={lats} as={as} gs={gs} headings={headings} correctedAlts={correctedAlts}></DebreifPage>,currentPage:0,pageDesc:"Debrief"})
    })
  }
  navItemClicked(event){
    console.log(event)
    let a = this
    if(event != this.state.currentPage){
      if(event == 0){
        if(a.state.lastID != ""){
          a.loadFromCloud(a.state.lastID)
          a.setState({currentPage:0,pageDesc:"Debrief",drawerOpen:false})
        }
      }
      if(event == 1){
        a.setState({debreif:<FlightSelection debreifFunc={this.loadFromCloud}></FlightSelection>,currentPage:1,pageDesc:"Flights",drawerOpen:false})
      }
      if(event == 2){

      }
    }
  }
  toggleDrawer(){
    this.setState({drawerOpen:!this.state.drawerOpen})
  }
  toggleShare(){
    this.setState({sharePopUpOpen:!this.state.sharePopUpOpen})
  }
  render() {

      return(
        <div>
        <Backdrop open={this.state.loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
          <Modal open={this.state.uploadPopUpOpen} onClose={this.togglePopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Card style = {{marginTop : '50vh'}}>
              <CSVReader onDrop={this.handleOnDrop} onError={this.handleOnError} addRemoveButton onRemoveFile={this.handleOnRemoveFile}>
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
              <Button style = {{alignSelf : 'center'}} onClick={this.processData3}>Load File</Button>
            </Card>
          </Modal>
          <Modal open={this.state.sharePopUpOpen} onClose={this.toggleShare} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Card style = {{marginTop : '50vh'}}>
              <h1>https://debrief.seerflightsystems.com/flight/{firebase.auth().currentUser.email}/{this.state.lastID}</h1>
            </Card>
          </Modal>
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.toggleDrawer}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6">
                {this.state.pageDesc}
              </Typography>
              <Button style = {{marginLeft: '70vw', margintRight:'250px'}} onClick = {this.togglePopUp} hidden={this.state.isMobile}>{firebase.auth().currentUser.email}</Button>
              <Button style = {{marginLeft: '5vw', margintRight:'250px'}} onClick = {this.toggleShare} disabled={this.state.lastID==""}>Share</Button>
            </Toolbar>
          </AppBar>
          <Drawer anchor="left" open={this.state.drawerOpen} style = {{width:'15vw'}} onClose={this.toggleDrawer}>
              <img src={seerLogo}  width="240"></img>
              <Divider />
              <List style = {{width:256}}>
                <ListItem button key={"Debrief"} onClick={()=> this.navItemClicked(0)} value={0}>
                  <ListItemIcon><MapSharpIcon></MapSharpIcon></ListItemIcon>
                  <ListItemText primary={"Debrief"} />
                </ListItem>
                <ListItem button key={"Flights"} onClick={()=> this.navItemClicked(1)}>
                  <ListItemIcon><FlightTakeoffSharpIcon></FlightTakeoffSharpIcon></ListItemIcon>
                  <ListItemText primary={"Flights"} />
                </ListItem>
                <ListItem button key={"Account"} onClick={()=> this.navItemClicked(2)}>
                  <ListItemIcon><AccountBoxSharpIcon></AccountBoxSharpIcon></ListItemIcon>
                  <ListItemText primary={"Account"} />
                </ListItem>
              </List>
              <Divider />
          </Drawer>
          {this.state.debreif}
        </div>

      )

  }
}
export default MyResponsiveGrid
