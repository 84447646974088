import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
class FlightSelection extends Component {
    constructor(props){
        super(props)
        this.state = {
            rows : [],
            debreifFunc : props.debreifFunc,
            loading : false
        }
        this.debreifFlight = this.debreifFlight.bind(this)
        this.deleteFlight = this.deleteFlight.bind(this)
        this.refreshRows = this.refreshRows.bind(this)
    }
    componentDidMount(){
        let db = firebase.firestore()
        let docRef = db.collection(firebase.auth().currentUser.email).doc('flights').collection('flights')
        let rows = []
        let a = this
        docRef.get().then(function(querySnapshot){
            
            querySnapshot.forEach(function(doc){
                console.log(querySnapshot.size)
                console.log(doc.id)
                var dateTime = new Date(parseInt(doc.id)*1000)
                console.log(dateTime.toISOString())
                rows.push(<TableRow key={doc.id}>
                <TableCell component="th" scope="row">
                  {dateTime.toISOString()}
                </TableCell>
                <TableCell align="right">{<Button onClick={e=>a.debreifFlight(doc.id)} value={doc.id.toString()}>Debrief</Button>}</TableCell>
                <TableCell align="right">{<Button>Edit</Button>}</TableCell>
                <TableCell align="right">{<Button onClick={e=>a.deleteFlight(doc.id)}>Delete</Button>}</TableCell>
              </TableRow>)
                if(rows.length == querySnapshot.size){
                    console.log('done')
                    a.setState({rows:rows})
                }
            })
        })
    }
    refreshRows(){
        let db = firebase.firestore()
        let docRef = db.collection(firebase.auth().currentUser.email).doc('flights').collection('flights')
        let rows = []
        let a = this
        docRef.get().then(function(querySnapshot){
            
            querySnapshot.forEach(function(doc){
                console.log(querySnapshot.size)
                console.log(doc.id)
                var dateTime = new Date(parseInt(doc.id)*1000)
                rows.push(<TableRow key={doc.id}>
                <TableCell component="th" scope="row">
                  {dateTime.toISOString()}
                </TableCell>
                <TableCell align="right">{<Button onClick={e=>a.debreifFlight(doc.id)} value={doc.id.toString()}>Debreif</Button>}</TableCell>
                <TableCell align="right">{<Button>Edit</Button>}</TableCell>
                <TableCell align="right">{<Button onClick={e=>a.deleteFlight(doc.id)}>Delete</Button>}</TableCell>
              </TableRow>)
                if(rows.length == querySnapshot.size){
                    console.log('done')
                    a.setState({rows:rows})
                }
            })
        })
    }
    debreifFlight(event){
        console.log(event)
        this.props.debreifFunc(event)
    }
    deleteFlight(event){
        this.setState({loading:true})
        console.log(event)
        let a = this
        let db = firebase.firestore()
        db.collection(firebase.auth().currentUser.email).doc('flights').collection('flights').doc(event).delete().then(function(){
            console.log("Doc Deleted!")
            a.refreshRows()
            a.setState({loading:false})
        }).catch(function(error){
            console.log(error)
        })
    }
    render(){
        return(
        <div>
        <TableContainer component={Paper} style = {{marginTop : '100px'}}>
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Flight Date and Time</TableCell>
                  <TableCell align="right">Debrief</TableCell>
                  <TableCell align="right">Edit</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {this.state.rows}
               
              </TableBody>
            </Table>
          </TableContainer>
          </div>)
    }
}

export default FlightSelection